<template>
    <g>
    	<path d="M12,17H8.4l-0.5-2h4c0.3,0,0.5-0.2,0.5-0.5S12.2,14,12,14H7.7L6,7h16.8l-1.3,3.9c-0.1,0.3,0.1,0.5,0.3,0.6
    		c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.5-0.3L24,6.7c0-0.2,0-0.3-0.1-0.5C23.8,6.1,23.7,6,23.5,6H5.8L4.5,0.4C4.4,0.2,4.2,0,4,0H0.5
    		C0.2,0,0,0.2,0,0.5S0.2,1,0.5,1h3.1l3.8,16.1C6.6,17.4,6,18.1,6,19c0,1.1,0.9,2,2,2s2-0.9,2-2c0-0.4-0.1-0.7-0.3-1H12
    		c0.3,0,0.5-0.2,0.5-0.5S12.3,17,12,17z M9,19c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S9,18.4,9,19z"/>
    	<path d="M18.5,13c-3,0-5.5,2.5-5.5,5.5s2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5S21.5,13,18.5,13z M18.5,23C16,23,14,21,14,18.5
    		c0-2.5,2-4.5,4.5-4.5s4.5,2,4.5,4.5C23,21,21,23,18.5,23z"/>
    	<path d="M21,18h-2v-2c0-0.3-0.2-0.5-0.5-0.5S18,15.7,18,16v2h-2c-0.3,0-0.5,0.2-0.5,0.5S15.7,19,16,19h2v2c0,0.3,0.2,0.5,0.5,0.5
    		S19,21.3,19,21v-2h2c0.3,0,0.5-0.2,0.5-0.5S21.3,18,21,18z"/>
    </g>
</template>

<script>
export default {
  name: "AddToCartIcon"
}
</script>
